@import "../../assets/scss/variables.scss";

.userDetail {
    h1 {
        margin-bottom: 35px;
        img{
            width: 25px;
            cursor: pointer;
            margin: 6px 20px 0 0;
        }
    }
    &__input {
        @include min-screen($small) {
            display: inline-flex;
            flex-direction: column;
            width: calc(50% - 8px);
            margin-right: 15px;
        }
        &:nth-of-type(even) {
            margin-right: 0;
        }
    }
}
