.flex {
    display: flex;
}
.flexWrap {
    display: flex;
    flex-wrap: wrap;
}
.alignCenter {
    align-items: center;
}
.justifyBetween {
    justify-content: space-between;
}
.justifyCenter {
    justify-content: center;
}
.flexCol{
    flex-direction: column;
}