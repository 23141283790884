* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    height: 100%;
}
body {
    font-size: $font-size-base;
    color: $color-dark;
    font-family: $font-family-primary;
    line-height: $line-height-base;
    background-color: $color-white;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    &.is-hidden {
        overflow: hidden;
    }
}
#root{
    height: 100%;
}
a {
    outline: none;
    text-decoration: none;
    cursor: pointer;
}
img {
    display: block;
    max-width: 100%;
}

.d-none {
    display: none;
}
