@import "../../assets/scss/variables.scss";
.userList {
  padding: 40px 60px;
  @include max-screen($tablet) {
    padding: 40px;
  }
  @include max-screen($mobile) {
    padding: 20px;
  }
  &__header {
    gap: 10px;
  }
  h2 {
    img {
      margin-right: 15px;
      width: 20px;
      cursor: pointer;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &__date_picker {
      min-height: 38px;
      margin-right: 15px;
      border: 1px solid $color-grey;
      width: -webkit-fill-available;
      &::placeholder {
        color: $color-grey;
      }
    }
  }
  &__input {
    position: relative;
    max-width: 250px;
    width: 100%;
    div {
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
    img {
      width: 18px;
    }
    input {
      border: 1px solid $color-grey;
      padding: 10px 10px 10px 35px;
      border-radius: 5px;
      &::placeholder {
        color: $color-grey;
      }
    }
  }
  &__search {
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    height: 37px;
  }
  &__table {
    border-radius: 5px;
    overflow-x: auto;
    border: 1px solid $color-grey;
    margin: 20px 0;
    &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba($color-dark, 0.1);
    }
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba($color-dark, 0.2);
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        background-color: $color-primary;
        th {
          padding: 12px 20px;
          text-align: left;
          min-width: 200px;
          border-right: 1px solid $color-grey;
          white-space: nowrap;
          @include max-screen($mobile) {
            min-width: 150px;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            padding: 15px 20px;
            border-right: 1px solid $color-grey;
            border-bottom: 1px solid $color-grey;
            &:last-child {
              border-right: 0;
            }
            a {
              white-space: nowrap;
              color: $color-blue;
            }
            img {
              cursor: pointer;
              width: 15px;
            }
          }
          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  &__noData {
    text-align: center;
  }
}
