// Colors
$color-primary: #e3ebeb;
$color-dark: #161615;
$color-grey: #a8a5a4;
$color-light: #dedddc;
$color-white: #ffffff;
$color-error: #ff0000;
$color-blue: #206bcf;

// Base
$font-size-base: 16px;
$line-height-base: 1.4;
$font-family-primary: "Public Sans", sans-serif;
$font-family-secondary: "Playfair Display", serif;

// Breakpoints
$small: 575px;
$mobile: 767px;
$tablet: 991px;
$desktop: 1199px;

// typography
$font-size-h1: 38px;
$font-size-h2: 24px;
// image path
$imgPath: "/assets/images/";

// mixins
@import "mixins";
