@import "./variables.scss";

.login {
    &__innerContent{
        flex: 1;
    }
    &__actions {
        @include max-screen($tablet) {
            gap: 10px;
        }
        button {
            min-width: 130px;
        }
    }
    &__forgot {
        align-self: flex-start;
        cursor: pointer;
        color: $color-grey;
        font-size: 14px;
        margin-top: 40px;
        -webkit-tap-highlight-color: transparent;
        @include max-screen($mobile) {
            color: $color-dark;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
