@import "../../assets/scss/variables.scss";
.pagination {
  justify-content: space-between;
  @include max-screen($mobile) {
    justify-content: center;
  }
  p {
    font-size: 14px;
    @include max-screen($mobile) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  &__list {
    gap: 4px;
    img {
      width: 15px;
      cursor: pointer;
    }
    span {
      font-size: 14px;
      padding: 4px 10px;
      border-radius: 2px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &.active,
      &:hover {
        background-color: $color-primary;
      }
      &.active {
        cursor: default;
      }
    }
  }
}
