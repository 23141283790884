@import "../../assets/scss/variables.scss";
.snackbar {
    opacity: 0;
    visibility: hidden;
    min-width: 300px;
    background-color: $color-dark;
    color: $color-white;
    border-radius: 4px;
    padding: 12px 15px;
    position: fixed;
    z-index: 5;
    left: 50%;
    bottom: 0;
    gap: 10px;
    transition: 0.1s ease-in-out;
    transform: translate(-50%, 0);
    max-width: 450px;
    img {
        width: 18px;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -20px);
    }
}
