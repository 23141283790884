@import "./variables.scss";
.inputField {
    margin-bottom: 30px;
    label {
        font-size: 13px;
        padding-bottom: 5px;
        letter-spacing: 1px;
        font-weight: 600;
        span {
            color: $color-error;
            margin-top: -2px;
        }
    }
    &__input {
        position: relative;
        img {
            cursor: pointer;
            width: 15px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        input,
        textarea {
            width: 100%;
            outline: none;
            border: 0;
            border-bottom: 1px solid $color-grey;
            padding-bottom: 5px;
            font-weight: 300;
            font-family: $font-family-primary;
            appearance: none;
            background-color: transparent;
            border-radius: 0;
            @include max-screen($mobile) {
                border-color: $color-dark;
            }
            &::placeholder {
                color: $color-grey;
                @include max-screen($mobile) {
                    color: $color-dark;
                }
            }
            &::-ms-reveal,
            &::-ms-clear {
                display: none;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s;
            }
            &:focus {
                border-color: $color-grey;
            }
            &:read-only {
                color: rgba($color-grey, 0.6);
                border-color: rgba($color-grey, 0.6);
            }
        }
        textarea {
            resize: none;
            border: 1px solid $color-grey;
            border-radius: 2px;
            padding: 10px;
            min-height: 85px;
            @include max-screen($mobile) {
                border-color: $color-dark;
            }
        }
    }
    &__inputIcon {
        input {
            padding-right: 20px;
        }
    }
    &__error {
        font-size: 13px;
        color: $color-error;
        padding-top: 2px;
        font-weight: 300;
        @include max-screen($mobile) {
            font-size: 12px;
        }
    }
}
