.button {
    border: 0;
    height: 40px;
    outline: none;
    font-size: 12px;
    appearance: none;
    font-weight: 700;
    padding: 5px 20px;
    user-select: none;
    color: $color-white;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    background-color: $color-dark;
    font-family: $font-family-primary;
    letter-spacing: 2px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    @include max-screen($desktop) {
        padding: 5px 12px;
    }
    @include max-screen($tablet) {
        font-size: 10px;
        padding: 5px 10px;
    }
    &+.button{
        margin-left: 10px;
    }
    &:hover {
        background-color: rgba($color-dark, 0.8);
    }
    &.primary {
        background-color: $color-primary;
        color: $color-dark;
        &:hover {
            background-color: rgba($color-primary, 0.8);
        }
    }
    &.disabled {
        background-color: $color-light;
        color: $color-white;
        cursor: not-allowed;
    }
}
