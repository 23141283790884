@import "../../assets/scss/variables.scss";

.header {
  padding: 15px 20px;
  box-shadow: 0px 1px 0px $color-light;
  img {
    max-width: 150px;
    @include max-screen($tablet) {
      max-width: 120px;
    }
  }
}
