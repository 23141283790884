@import "variables.scss";
h1,
h2 {
    font-weight: 400;
    color: $color-dark;
    line-height: 1.2;
}
h1 {
    font-size: $font-size-h1;
    font-family: $font-family-secondary;
    margin-bottom: 10px;
    @include max-screen($tablet) {
        font-size: 30px;
    }
    @include max-screen($mobile) {
        font-size: 25px;
    }
}
h2 {
    font-size: $font-size-h2;
    @include max-screen($mobile) {
        font-size: 20px;
    }
}
