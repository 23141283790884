@import "../../assets/scss/variables.scss";
.modal {
  z-index: 1; 
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba($color-dark, 0.4); 
  overflow-y: scroll;
  &.modalClose{
    display: none;
  }
  &__content{
    background-color: $color-white;
    border-radius: 5px;
    padding: 30px;
    max-width: 500px;
    text-align: center;
    p{
      font-size: 20px;
    }
  }
  &__actions{
    padding-top: 30px;
    button{
      min-width: 100px;
    }
  }
}